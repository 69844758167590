import React from "react"

import { Container, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import iconBg from "../../assets/images/icon_bg.png"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url("${iconBg}")`,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(6, 0),
  },

  headline: {
    marginBottom: 0,

    [theme.breakpoints.up(`md`)]: {
      marginBottom: theme.spacing(1),
    },
  },

  sub: {
    display: `none`,

    [theme.breakpoints.up(`md`)]: {
      display: `block`,
    },
  },
}))

const HeadlineView = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Typography variant="h1" align="center" className={classes.headline}>
          To Do Cards, handige kaartjes voor het bijhouden van jouw taken!
        </Typography>

        <Typography
          variant="h3"
          component="p"
          align="center"
          className={classes.sub}
        >
          Met onze To Do Cards houd je overzicht en kan je je beter focussen op
          jouw werkzaamheden.
        </Typography>
      </Container>
    </div>
  )
}

export default HeadlineView
