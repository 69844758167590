import React from "react"

import { Container, Typography } from "@material-ui/core"

import clsx from "clsx"

const TodoListTipsView = () => {
  return (
    <div className={clsx([`blockPaddingTop`, `blockPaddingBottom`])}>
      <Container maxWidth="md">
        <Typography variant="h2" gutterBottom>
          6 tips voor de perfecte to do list
        </Typography>

        <Typography paragraph={true}>
          Een to do list is ideaal voor het creëren van overzicht. Het afvinken
          van taken kan motiverend werken en zorgen voor een productieve dag.
          Echter, een te lange lijst met veel te grote taken kan juist het
          tegenovergestelde effect hebben. Om dit te voorkomen hebben wij een
          lijst samengesteld met 6 tips om een perfecte to do list te maken.
        </Typography>

        <Typography variant="h3" gutterBottom>
          1. Concrete taken
        </Typography>

        <Typography paragraph={true}>
          Zorg dat je taken een duidelijk eindpunt hebben, anders blijf je
          eindeloos bij een bepaalde taak hangen. ‘Werken aan project X’ is te
          vaag, maar ‘Projectplan afronden voor project X’ is al veel concreter.
          Probeer de acties zo concreet mogelijk op te schrijven, dit verlaagt
          de drempel om aan een bepaalde taak te beginnen.
        </Typography>

        <Typography variant="h3" gutterBottom>
          2. Maak de lijst niet te lang
        </Typography>

        <Typography paragraph={true}>
          Mensen overschatten vaak wat ze in een korte tijd kunnen doen. Dat
          resulteert in te lange, demotiverende to do lijstjes. Wees
          realistisch, schat bijvoorbeeld per taak in hoeveel tijd het gaat
          kosten. Zo voorkom je dat je per ongeluk teveel taken op jouw lijstje
          zet.
        </Typography>

        <Typography variant="h3" gutterBottom>
          3. Kleine taken
        </Typography>

        <Typography paragraph={true}>
          Afvinken is leuk! Het zorgt voor motivatie en geeft extra energie,
          deel daarom jouw grote taken op in kleine taakjes. Daarnaast is de
          drempel om te beginnen aan een kleine taak lager en zal je sneller de
          energie vinden om nog ‘even’ een extra taak af te ronden.
        </Typography>

        <Typography variant="h3" gutterBottom>
          4. Begin met de minst leuke taak
        </Typography>

        <Typography paragraph={true}>
          Door te beginnen met de taak die je het minst leuk vindt voorkom je
          dat je er de hele dag tegenop gaat kijken. Als je deze taak dan gedaan
          hebt, dan valt de rest van je lijst mee. Een alternatief is om,
          voordat je aan de minst leuke taak begint, eerst nog een heel klein
          ander taakje te doen om in de ‘flow’ te komen.
        </Typography>

        <Typography variant="h3" gutterBottom>
          5. Volgorde vastleggen
        </Typography>

        <Typography paragraph={true}>
          Schrijf de taken op in de volgorde waarop je ze gaat afwerken. Zo
          voorkom je dat je minder leuke taken gaat uitstellen en deze allemaal
          op het einde moet doen. Wissel bijvoorbeeld leuke en minder leuke
          taken met elkaar af.
        </Typography>

        <Typography variant="h3" gutterBottom>
          6. Maak jouw to do list de avond van tevoren
        </Typography>

        <Typography paragraph={true}>
          Bedenk de avond van tevoren al wat je de volgende dag wilt gaan doen
          en maak hier een to do list van. Zo kan je de volgende dag jouw nieuwe
          energie direct gebruiken om productief aan de slag te gaan!
        </Typography>
      </Container>
    </div>
  )
}

export default TodoListTipsView
