import React, { useState } from "react"

import {
  Box,
  Button,
  Dialog,
  Drawer,
  Grid,
  Typography,
} from "@material-ui/core"

import { Alert } from "@material-ui/lab"
import { AddShoppingCart } from "@material-ui/icons"

import { Link as GatsbyLink } from "gatsby"
import clsx from "clsx"

import { ViewProps } from "./add-to-cart-button-types"
import { productData } from "../../../data/products"
import CentsToEuro from "../../../utils/cents-to-euro"
import Image from "../image/image-view"

const ProductCardView = ({
  product,
  expand = false,
  addProduct,
}: ViewProps) => {
  const [showDrawer, setShowDrawer] = useState(false)
  const [showDialog, setShowDialog] = useState(false)

  const toggleDrawer = () => {
    setShowDrawer(show => !show)
  }

  const toggleDialog = () => {
    setShowDialog(show => !show)
  }

  const handleAddToCart = () => {
    addProduct(product)

    if (window.innerWidth > 600) {
      toggleDialog()
    } else {
      toggleDrawer()
    }
  }

  const renderCallbackContent = (isDrawer = false) => {
    const { title, excerpt, price, images } = productData[product]

    return (
      <>
        <Alert severity="success">
          <span className="bold">{title}</span> is toegevoegd aan jouw
          winkelwagen!
        </Alert>

        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Image src={images[0]} alt={title} />
            </Grid>

            <Grid item xs={9}>
              <Typography variant="h3" gutterBottom>
                {title}
              </Typography>

              <Typography className="mb05">{excerpt}</Typography>

              <Typography className="mb1 bold">{CentsToEuro(price)}</Typography>

              <Button
                size="small"
                variant="contained"
                color="secondary"
                className={clsx({ floatRight: !isDrawer, mb1: isDrawer })}
                fullWidth={isDrawer}
                component={GatsbyLink}
                to="/bestellen/winkelwagen"
              >
                Ik ga bestellen
              </Button>

              <Button
                size="small"
                className={clsx({ mr1: !isDrawer, floatRight: !isDrawer })}
                fullWidth={isDrawer}
                onClick={isDrawer ? toggleDrawer : toggleDialog}
              >
                Verder winkelen
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    )
  }

  return (
    <>
      <Button
        size={expand ? `large` : `small`}
        fullWidth={expand}
        variant="contained"
        color="secondary"
        onClick={handleAddToCart}
      >
        {expand ? (
          <>
            <AddShoppingCart className="mr05" />
            In winkelwagen
          </>
        ) : (
          <AddShoppingCart />
        )}
      </Button>

      <Drawer anchor="bottom" open={showDrawer} onClose={toggleDrawer}>
        {renderCallbackContent(true)}
      </Drawer>

      <Dialog open={showDialog} onClose={toggleDialog} fullWidth={true}>
        {renderCallbackContent()}
      </Dialog>
    </>
  )
}

export default ProductCardView
