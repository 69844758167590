import React from "react"

import { Container, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import clsx from "clsx"

import bullet from "./bullet.svg"
import todoImage from "./drawing.svg"

const items: {
  title: string
  description: string
}[] = [
  {
    title: `Bedrukt met 9 lijntjes en vakjes aan één kant`,
    description: `Voor de meeste gebruikers is één kaartje per dag voldoende.`,
  },
  {
    title: `Van A6 formaat (10,5 x 14,8 cm)`,
    description: `Handzaam, maar groot genoeg om alles op te kunnen schrijven.`,
  },
  {
    title: `Gemaakt van 300 grams natuurkarton`,
    description: `De kaartjes zijn lekker stevig en het karton is FSC-gecertificeerd.`,
  },
  {
    title: `Multifunctioneel`,
    description: `To Do Cards zijn ideaal voor op het werk, maar ook voor bijvoorbeeld persoonlijke- of huishoudelijke taken.`,
  },
]

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
  },

  wrapper: {
    display: `flex`,
    alignItems: `center`,
  },

  image: {
    width: `55%`,
    paddingRight: theme.spacing(10),
    display: `none`,

    [theme.breakpoints.up(`md`)]: {
      display: `block`,
    },
  },

  content: {
    width: `100%`,

    [theme.breakpoints.up(`md`)]: {
      width: `45%`,
    },
  },

  itemWrapper: {
    display: `flex`,
    alignItems: `center`,
  },
}))

const TodoCardsPropertiesView = () => {
  const classes = useStyles()

  return (
    <div
      className={clsx([classes.root, "blockPaddingTop", "blockPaddingBottom"])}
    >
      <Container>
        <div className={classes.wrapper}>
          <div className={classes.image}>
            <img src={todoImage} width="100%" alt="Onze To Do Cards zijn..." />
          </div>

          <div className={classes.content}>
            <Typography variant="h2" className="mb2">
              Onze To Do Cards zijn...
            </Typography>

            {items.map((item, i) => (
              <div
                className={clsx(
                  classes.itemWrapper,
                  i + 1 !== items.length && `mb2`
                )}
                key={i}
              >
                <div className="mr1">
                  <img src={bullet} width="20px" alt={item.title} />
                </div>

                <div>
                  <Typography variant="h4">{item.title}</Typography>
                  <Typography>{item.description}</Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default TodoCardsPropertiesView
