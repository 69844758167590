import React from "react"

import { Container, Grid, Typography } from "@material-ui/core"
import { Helmet } from "react-helmet"

import MainShell from "../components/main-shell"
import Headline from "../components/headline"
import { Product } from "../api/sync"
import ProductCard from "../components/product-card/product-card-view"
import TodoCardsProperties from "../components/sections/todo-cards-properties"
import TodoListTips from "../components/sections/todo-list-tips"

const IndexPage = () => {
  return (
    <MainShell
      header={<Headline />}
      wrapWithContainer={false}
      addPadding={false}
    >
      <Helmet>
        <title>
          Todocards.nl - handige kaartjes voor het bijhouden van jouw taken!
        </title>

        <meta
          name="description"
          content="Met todocards wordt het makkelijk om jouw taken bij te houden, zou houd je overzicht en kan je je beter focussen. Snelle bezorging en gratis retour binnen 14 dagen."
        />
      </Helmet>

      <Container className="blockPaddingTop blockPaddingBottom">
        <Typography variant="h2" className="mb2">
          Onze producten
        </Typography>

        <Grid container spacing={4}>
          {[Product.TODO, Product.TODOXL, Product.TODOXS].map(
            (product: Product) => (
              <Grid item xs={12} sm={4} key={product}>
                <ProductCard product={product} />
              </Grid>
            )
          )}
        </Grid>
      </Container>

      <TodoCardsProperties />
      <TodoListTips />
    </MainShell>
  )
}

export default IndexPage
