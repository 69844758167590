import React from "react"

import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { productData } from "../../data/products"
import { ViewProps } from "./product-card-types"
import AddToCartButton from "../utils/add-to-cart-button"
import CentsToEuro from "../../utils/cents-to-euro"
import Image from "../utils/image"

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    height: `100%`,
    display: `flex`,
    flexDirection: `column`,
  },

  description: {
    padding: `0 ${theme.spacing(2)}px`,
    display: `flex`,
    flexDirection: `column`,
    flexGrow: 1,
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
  },

  footer: {
    display: `flex`,
    justifyContent: `space-between`,
    alignItems: `center`,
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px`,
  },

  price: {
    fontWeight: `bold`,
    fontSize: `1rem`,
  },
}))

const ProductCardView = ({ product }: ViewProps) => {
  const { title, description, price, images } = productData[product]
  const classes = useStyles()

  return (
    <div className={classes.card}>
      <Image src={images[0]} alt={title} borderRadiusBottom={false} />

      <div className={classes.description}>
        <Typography variant="h3" className="mb1">
          {title}
        </Typography>

        <Typography variant="body1" className="mb1">
          {description}
        </Typography>
      </div>

      <div className={classes.footer}>
        <span className={classes.price}>{CentsToEuro(price)}</span>
        <AddToCartButton product={product} />
      </div>
    </div>
  )
}

export default ProductCardView
